/**
 * 车牌号头部列表
 */
const plateNumberHeadList = [
    { name: '川A(CA)', value: '川A', },
    { name: '川B(CB)', value: '川B', },
    { name: '川C(CC)', value: '川C', },
    { name: '川D(CD)', value: '川D', },
    { name: '川E(CE)', value: '川E', },
    { name: '川F(CF)', value: '川F', },
    { name: '川G(CG)', value: '川G', },
    { name: '川H(CH)', value: '川H', },
    { name: '川J(CJ)', value: '川J', },
    { name: '川K(CK)', value: '川K', },
    { name: '川L(CL)', value: '川L', },
    { name: '川M(CM)', value: '川M', },
    { name: '川Q(CQ)', value: '川Q', },
    { name: '川R(CR)', value: '川R', },
    { name: '川S(CS)', value: '川S', },
    { name: '川T(CT)', value: '川T', },
    { name: '川U(CU)', value: '川U', },
    { name: '川V(CV)', value: '川V', },
    { name: '川W(CW)', value: '川W', },
    { name: '川X(CX)', value: '川X', },
    { name: '川Y(CY)', value: '川Y', },
    { name: '川Z(CZ)', value: '川Z', },
    { name: '鄂A(EA)', value: '鄂A', },
    { name: '鄂B(EB)', value: '鄂B', },
    { name: '鄂C(EC)', value: '鄂C', },
    { name: '鄂D(ED)', value: '鄂D', },
    { name: '鄂E(EE)', value: '鄂E', },
    { name: '鄂F(EF)', value: '鄂F', },
    { name: '鄂G(EG)', value: '鄂G', },
    { name: '鄂H(EH)', value: '鄂H', },
    { name: '鄂J(EJ)', value: '鄂J', },
    { name: '鄂K(EK)', value: '鄂K', },
    { name: '鄂L(EL)', value: '鄂L', },
    { name: '鄂M(EM)', value: '鄂M', },
    { name: '鄂N(EN)', value: '鄂N', },
    { name: '鄂P(EP)', value: '鄂P', },
    { name: '鄂Q(EQ)', value: '鄂Q', },
    { name: '鄂R(ER)', value: '鄂R', },
    { name: '甘A(GA)', value: '甘A', },
    { name: '甘B(GB)', value: '甘B', },
    { name: '甘C(GC)', value: '甘C', },
    { name: '甘D(GD)', value: '甘D', },
    { name: '甘E(GE)', value: '甘E', },
    { name: '甘F(GF)', value: '甘F', },
    { name: '甘G(GG)', value: '甘G', },
    { name: '甘H(GH)', value: '甘H', },
    { name: '甘J(GJ)', value: '甘J', },
    { name: '甘K(GK)', value: '甘K', },
    { name: '甘L(GL)', value: '甘L', },
    { name: '甘M(GM)', value: '甘M', },
    { name: '甘N(GN)', value: '甘N', },
    { name: '甘P(GP)', value: '甘P', },
    { name: '贵A(GA)', value: '贵A', },
    { name: '贵B(GB)', value: '贵B', },
    { name: '贵C(GC)', value: '贵C', },
    { name: '贵D(GD)', value: '贵D', },
    { name: '贵E(GE)', value: '贵E', },
    { name: '贵F(GF)', value: '贵F', },
    { name: '贵G(GG)', value: '贵G', },
    { name: '贵H(GH)', value: '贵H', },
    { name: '贵J(GJ)', value: '贵J', },
    { name: '赣A(GA)', value: '赣A', },
    { name: '赣B(GB)', value: '赣B', },
    { name: '赣C(GC)', value: '赣C', },
    { name: '赣D(GD)', value: '赣D', },
    { name: '赣E(GE)', value: '赣E', },
    { name: '赣F(GF)', value: '赣F', },
    { name: '赣G(GG)', value: '赣G', },
    { name: '赣H(GH)', value: '赣H', },
    { name: '赣J(GJ)', value: '赣J', },
    { name: '赣K(GK)', value: '赣K', },
    { name: '赣L(GL)', value: '赣L', },
    { name: '赣M(GM)', value: '赣M', },
    { name: '桂A(GA)', value: '桂A', },
    { name: '桂B(GB)', value: '桂B', },
    { name: '桂C(GC)', value: '桂C', },
    { name: '桂D(GD)', value: '桂D', },
    { name: '桂E(GE)', value: '桂E', },
    { name: '桂F(GF)', value: '桂F', },
    { name: '桂G(GG)', value: '桂G', },
    { name: '桂H(GH)', value: '桂H', },
    { name: '桂J(GJ)', value: '桂J', },
    { name: '桂K(GK)', value: '桂K', },
    { name: '桂L(GL)', value: '桂L', },
    { name: '桂M(GM)', value: '桂M', },
    { name: '桂N(GN)', value: '桂N', },
    { name: '桂P(GP)', value: '桂P', },
    { name: '桂R(GR)', value: '桂R', },
    { name: '沪A(HA)', value: '沪A', },
    { name: '沪B(HB)', value: '沪B', },
    { name: '沪C(HC)', value: '沪C', },
    { name: '沪D(HD)', value: '沪D', },
    { name: '沪E(HE)', value: '沪E', },
    { name: '沪F(HF)', value: '沪F', },
    { name: '沪G(HG)', value: '沪G', },
    { name: '沪H(HH)', value: '沪H', },
    { name: '沪J(HJ)', value: '沪J', },
    { name: '沪K(HK)', value: '沪K', },
    { name: '沪L(HL)', value: '沪L', },
    { name: '沪M(HM)', value: '沪M', },
    { name: '沪N(HN)', value: '沪N', },
    { name: '黑A(HA)', value: '黑A', },
    { name: '黑B(HB)', value: '黑B', },
    { name: '黑C(HC)', value: '黑C', },
    { name: '黑D(HD)', value: '黑D', },
    { name: '黑E(HE)', value: '黑E', },
    { name: '黑F(HF)', value: '黑F', },
    { name: '黑G(HG)', value: '黑G', },
    { name: '黑H(HH)', value: '黑H', },
    { name: '黑J(HJ)', value: '黑J', },
    { name: '黑K(HK)', value: '黑K', },
    { name: '黑L(HL)', value: '黑L', },
    { name: '黑M(HM)', value: '黑M', },
    { name: '黑N(HN)', value: '黑N', },
    { name: '黑P(HP)', value: '黑P', },
    { name: '黑R(HR)', value: '黑R', },
    { name: '京A(JA)', value: '京A', },
    { name: '京B(JB)', value: '京B', },
    { name: '京C(JC)', value: '京C', },
    { name: '京D(JD)', value: '京D', },
    { name: '京E(JE)', value: '京E', },
    { name: '京F(JF)', value: '京F', },
    { name: '京G(JG)', value: '京G', },
    { name: '京H(JH)', value: '京H', },
    { name: '京J(JJ)', value: '京J', },
    { name: '京K(JK)', value: '京K', },
    { name: '京L(JL)', value: '京L', },
    { name: '京M(JM)', value: '京M', },
    { name: '京N(JN)', value: '京N', },
    { name: '京P(JP)', value: '京P', },
    { name: '京Q(JQ)', value: '京Q', },
    { name: '京V(JV)', value: '京V', },
    { name: '京Y(JY)', value: '京Y', },
    { name: '津A(JA)', value: '津A', },
    { name: '津B(JB)', value: '津B', },
    { name: '津C(JC)', value: '津C', },
    { name: '津D(JD)', value: '津D', },
    { name: '津E(JE)', value: '津E', },
    { name: '津F(JF)', value: '津F', },
    { name: '津G(JG)', value: '津G', },
    { name: '津H(JH)', value: '津H', },
    { name: '津J(JJ)', value: '津J', },
    { name: '津K(JK)', value: '津K', },
    { name: '津L(JL)', value: '津L', },
    { name: '津M(JM)', value: '津M', },
    { name: '津N(JN)', value: '津N', },
    { name: '津Q(JQ)', value: '津Q', },
    { name: '津R(JR)', value: '津R', },
    { name: '吉A(JA)', value: '吉A', },
    { name: '吉B(JB)', value: '吉B', },
    { name: '吉C(JC)', value: '吉C', },
    { name: '吉D(JD)', value: '吉D', },
    { name: '吉E(JE)', value: '吉E', },
    { name: '吉F(JF)', value: '吉F', },
    { name: '吉G(JG)', value: '吉G', },
    { name: '吉H(JH)', value: '吉H', },
    { name: '吉J(JJ)', value: '吉J', },
    { name: '吉K(JK)', value: '吉K', },
    { name: '冀A(JA)', value: '冀A', },
    { name: '冀B(JB)', value: '冀B', },
    { name: '冀C(JC)', value: '冀C', },
    { name: '冀D(JD)', value: '冀D', },
    { name: '冀E(JE)', value: '冀E', },
    { name: '冀F(JF)', value: '冀F', },
    { name: '冀G(JG)', value: '冀G', },
    { name: '冀H(JH)', value: '冀H', },
    { name: '冀J(JJ)', value: '冀J', },
    { name: '冀R(JR)', value: '冀R', },
    { name: '冀T(JT)', value: '冀T', },
    { name: '晋A(JA)', value: '晋A', },
    { name: '晋B(JB)', value: '晋B', },
    { name: '晋C(JC)', value: '晋C', },
    { name: '晋D(JD)', value: '晋D', },
    { name: '晋E(JE)', value: '晋E', },
    { name: '晋F(JF)', value: '晋F', },
    { name: '晋H(JH)', value: '晋H', },
    { name: '晋J(JJ)', value: '晋J', },
    { name: '晋K(JK)', value: '晋K', },
    { name: '晋L(JL)', value: '晋L', },
    { name: '晋M(JM)', value: '晋M', },
    { name: '晋N(JN)', value: '晋N', },
    { name: '鲁A(LA)', value: '鲁A', },
    { name: '鲁B(LB)', value: '鲁B', },
    { name: '鲁C(LC)', value: '鲁C', },
    { name: '鲁D(LD)', value: '鲁D', },
    { name: '鲁E(LE)', value: '鲁E', },
    { name: '鲁F(LF)', value: '鲁F', },
    { name: '鲁G(LG)', value: '鲁G', },
    { name: '鲁H(LH)', value: '鲁H', },
    { name: '鲁J(LJ)', value: '鲁J', },
    { name: '鲁K(LK)', value: '鲁K', },
    { name: '鲁L(LL)', value: '鲁L', },
    { name: '鲁M(LM)', value: '鲁M', },
    { name: '鲁N(LN)', value: '鲁N', },
    { name: '鲁P(LP)', value: '鲁P', },
    { name: '鲁Q(LQ)', value: '鲁Q', },
    { name: '鲁R(LR)', value: '鲁R', },
    { name: '鲁U(LU)', value: '鲁U', },
    { name: '鲁V(LV)', value: '鲁V', },
    { name: '鲁Y(LY)', value: '鲁Y', },
    { name: '辽A(LA)', value: '辽A', },
    { name: '辽B(LB)', value: '辽B', },
    { name: '辽C(LC)', value: '辽C', },
    { name: '辽D(LD)', value: '辽D', },
    { name: '辽E(LE)', value: '辽E', },
    { name: '辽F(LF)', value: '辽F', },
    { name: '辽G(LG)', value: '辽G', },
    { name: '辽H(LH)', value: '辽H', },
    { name: '辽J(LJ)', value: '辽J', },
    { name: '辽K(LK)', value: '辽K', },
    { name: '辽L(LL)', value: '辽L', },
    { name: '辽M(LM)', value: '辽M', },
    { name: '辽N(LN)', value: '辽N', },
    { name: '辽P(LP)', value: '辽P', },
    { name: '闽A(MA)', value: '闽A', },
    { name: '闽B(MB)', value: '闽B', },
    { name: '闽C(MC)', value: '闽C', },
    { name: '闽D(MD)', value: '闽D', },
    { name: '闽E(ME)', value: '闽E', },
    { name: '闽F(MF)', value: '闽F', },
    { name: '闽G(MG)', value: '闽G', },
    { name: '闽H(MH)', value: '闽H', },
    { name: '闽J(MJ)', value: '闽J', },
    { name: '闽K(MK)', value: '闽K', },
    { name: '蒙A(MA)', value: '蒙A', },
    { name: '蒙B(MB)', value: '蒙B', },
    { name: '蒙C(MC)', value: '蒙C', },
    { name: '蒙D(MD)', value: '蒙D', },
    { name: '蒙E(ME)', value: '蒙E', },
    { name: '蒙F(MF)', value: '蒙F', },
    { name: '蒙G(MG)', value: '蒙G', },
    { name: '蒙H(MH)', value: '蒙H', },
    { name: '蒙J(MJ)', value: '蒙J', },
    { name: '蒙K(MK)', value: '蒙K', },
    { name: '蒙L(ML)', value: '蒙L', },
    { name: '蒙M(MM)', value: '蒙M', },
    { name: '宁A(NA)', value: '宁A', },
    { name: '宁B(NB)', value: '宁B', },
    { name: '宁C(NC)', value: '宁C', },
    { name: '宁D(ND)', value: '宁D', },
    { name: '宁E(NE)', value: '宁E', },
    { name: '青A(QA)', value: '青A', },
    { name: '青B(QB)', value: '青B', },
    { name: '青C(QC)', value: '青C', },
    { name: '青D(QD)', value: '青D', },
    { name: '青E(QE)', value: '青E', },
    { name: '青F(QF)', value: '青F', },
    { name: '青G(QG)', value: '青G', },
    { name: '青H(QH)', value: '青H', },
    { name: '琼A(QA)', value: '琼A', },
    { name: '琼B(QB)', value: '琼B', },
    { name: '琼C(QC)', value: '琼C', },
    { name: '琼D(QD)', value: '琼D', },
    { name: '琼E(QE)', value: '琼E', },
    { name: '琼F(QF)', value: '琼F', },
    { name: '皖A(WA)', value: '皖A', },
    { name: '皖B(WB)', value: '皖B', },
    { name: '皖C(WC)', value: '皖C', },
    { name: '皖D(WD)', value: '皖D', },
    { name: '皖E(WE)', value: '皖E', },
    { name: '皖F(WF)', value: '皖F', },
    { name: '皖G(WG)', value: '皖G', },
    { name: '皖H(WH)', value: '皖H', },
    { name: '皖J(WJ)', value: '皖J', },
    { name: '皖K(WK)', value: '皖K', },
    { name: '皖L(WL)', value: '皖L', },
    { name: '皖M(WM)', value: '皖M', },
    { name: '皖N(WN)', value: '皖N', },
    { name: '皖P(WP)', value: '皖P', },
    { name: '皖R(WR)', value: '皖R', },
    { name: '皖S(WS)', value: '皖S', },
    { name: '陕A(SA)', value: '陕A', },
    { name: '陕B(SB)', value: '陕B', },
    { name: '陕C(SC)', value: '陕C', },
    { name: '陕D(SD)', value: '陕D', },
    { name: '陕E(SE)', value: '陕E', },
    { name: '陕F(SF)', value: '陕F', },
    { name: '陕G(SG)', value: '陕G', },
    { name: '陕H(SH)', value: '陕H', },
    { name: '陕J(SJ)', value: '陕J', },
    { name: '陕K(SK)', value: '陕K', },
    { name: '陕U(SU)', value: '陕U', },
    { name: '陕V(SV)', value: '陕V', },
    { name: '苏A(SA)', value: '苏A', },
    { name: '苏B(SB)', value: '苏B', },
    { name: '苏C(SC)', value: '苏C', },
    { name: '苏D(SD)', value: '苏D', },
    { name: '苏E(SE)', value: '苏E', },
    { name: '苏F(SF)', value: '苏F', },
    { name: '苏G(SG)', value: '苏G', },
    { name: '苏H(SH)', value: '苏H', },
    { name: '苏J(SJ)', value: '苏J', },
    { name: '苏K(SK)', value: '苏K', },
    { name: '苏L(SL)', value: '苏L', },
    { name: '苏M(SM)', value: '苏M', },
    { name: '苏N(SN)', value: '苏N', },
    { name: '苏U(SU)', value: '苏U', },
    { name: '湘A(XA)', value: '湘A', },
    { name: '湘B(XB)', value: '湘B', },
    { name: '湘C(XC)', value: '湘C', },
    { name: '湘D(XD)', value: '湘D', },
    { name: '湘E(XE)', value: '湘E', },
    { name: '湘F(XF)', value: '湘F', },
    { name: '湘G(XG)', value: '湘G', },
    { name: '湘H(XH)', value: '湘H', },
    { name: '湘J(XJ)', value: '湘J', },
    { name: '湘K(XK)', value: '湘K', },
    { name: '湘L(XL)', value: '湘L', },
    { name: '湘M(XM)', value: '湘M', },
    { name: '湘N(XN)', value: '湘N', },
    { name: '湘U(XU)', value: '湘U', },
    { name: '湘S(XS)', value: '湘S', },
    { name: '新A(XA)', value: '新A', },
    { name: '新B(XB)', value: '新B', },
    { name: '新C(XC)', value: '新C', },
    { name: '新D(XD)', value: '新D', },
    { name: '新E(XE)', value: '新E', },
    { name: '新F(XF)', value: '新F', },
    { name: '新G(XG)', value: '新G', },
    { name: '新H(XH)', value: '新H', },
    { name: '新J(XJ)', value: '新J', },
    { name: '新K(XK)', value: '新K', },
    { name: '新L(XL)', value: '新L', },
    { name: '新M(XM)', value: '新M', },
    { name: '新N(XN)', value: '新N', },
    { name: '新P(XP)', value: '新P', },
    { name: '新Q(XQ)', value: '新Q', },
    { name: '新R(XR)', value: '新R', },
    { name: '新S(XS)', value: '新S', },
    { name: '粤A(YA)', value: '粤A', },
    { name: '粤B(YB)', value: '粤B', },
    { name: '粤C(YC)', value: '粤C', },
    { name: '粤D(YD)', value: '粤D', },
    { name: '粤E(YE)', value: '粤E', },
    { name: '粤F(YF)', value: '粤F', },
    { name: '粤G(YG)', value: '粤G', },
    { name: '粤H(YH)', value: '粤H', },
    { name: '粤J(YJ)', value: '粤J', },
    { name: '粤K(YK)', value: '粤K', },
    { name: '粤L(YL)', value: '粤L', },
    { name: '粤M(YM)', value: '粤M', },
    { name: '粤N(YN)', value: '粤N', },
    { name: '粤P(YP)', value: '粤P', },
    { name: '粤Q(YQ)', value: '粤Q', },
    { name: '粤R(YR)', value: '粤R', },
    { name: '粤S(YS)', value: '粤S', },
    { name: '粤T(YT)', value: '粤T', },
    { name: '粤U(YU)', value: '粤U', },
    { name: '粤V(YV)', value: '粤V', },
    { name: '粤W(YW)', value: '粤W', },
    { name: '粤Z(YZ)', value: '粤Z', },
    { name: '渝A(YA)', value: '渝A', },
    { name: '渝B(YB)', value: '渝B', },
    { name: '渝C(YC)', value: '渝C', },
    { name: '渝D(YD)', value: '渝D', },
    { name: '渝F(YF)', value: '渝F', },
    { name: '渝G(YG)', value: '渝G', },
    { name: '渝H(YH)', value: '渝H', },
    { name: '云A(YA)', value: '云A', },
    { name: '云C(YC)', value: '云C', },
    { name: '云D(YD)', value: '云D', },
    { name: '云E(YE)', value: '云E', },
    { name: '云F(YF)', value: '云F', },
    { name: '云G(YG)', value: '云G', },
    { name: '云H(YH)', value: '云H', },
    { name: '云J(YJ)', value: '云J', },
    { name: '云K(YK)', value: '云K', },
    { name: '云L(YL)', value: '云L', },
    { name: '云M(YM)', value: '云M', },
    { name: '云N(YN)', value: '云N', },
    { name: '云P(YP)', value: '云P', },
    { name: '云Q(YQ)', value: '云Q', },
    { name: '云R(YR)', value: '云R', },
    { name: '云S(YS)', value: '云S', },
    { name: '豫A(YA)', value: '豫A', },
    { name: '豫B(YB)', value: '豫B', },
    { name: '豫C(YC)', value: '豫C', },
    { name: '豫D(YD)', value: '豫D', },
    { name: '豫E(YE)', value: '豫E', },
    { name: '豫F(YF)', value: '豫F', },
    { name: '豫G(YG)', value: '豫G', },
    { name: '豫H(YH)', value: '豫H', },
    { name: '豫J(YJ)', value: '豫J', },
    { name: '豫K(YK)', value: '豫K', },
    { name: '豫L(YL)', value: '豫L', },
    { name: '豫M(YM)', value: '豫M', },
    { name: '豫N(YN)', value: '豫N', },
    { name: '豫P(YP)', value: '豫P', },
    { name: '豫Q(YQ)', value: '豫Q', },
    { name: '豫R(YR)', value: '豫R', },
    { name: '豫S(YS)', value: '豫S', },
    { name: '豫U(YU)', value: '豫U', },
    { name: '豫V(YV)', value: '豫V', },
    { name: '藏A(ZA)', value: '藏A', },
    { name: '藏B(ZB)', value: '藏B', },
    { name: '藏C(ZC)', value: '藏C', },
    { name: '藏D(ZD)', value: '藏D', },
    { name: '藏E(ZE)', value: '藏E', },
    { name: '藏F(ZF)', value: '藏F', },
    { name: '藏G(ZG)', value: '藏G', },
    { name: '浙A(ZA)', value: '浙A', },
    { name: '浙B(ZB)', value: '浙B', },
    { name: '浙C(ZC)', value: '浙C', },
    { name: '浙D(ZD)', value: '浙D', },
    { name: '浙E(ZE)', value: '浙E', },
    { name: '浙F(ZF)', value: '浙F', },
    { name: '浙G(ZG)', value: '浙G', },
    { name: '浙H(ZH)', value: '浙H', },
    { name: '浙J(ZJ)', value: '浙J', },
    { name: '浙K(ZK)', value: '浙K', },
    { name: '浙L(ZL)', value: '浙L', },
];

export default plateNumberHeadList;
