<template>
  <!-- 路内 抓拍记录 -->
  <div class="box-card" style="text-align: left">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="车牌号：">
        <el-autocomplete :trigger-on-focus="false" popper-class="my-select" v-model="queryForm.plate_number_head"
          :fetch-suggestions="querySearch" placeholder="湘A" @select="handleSelect">
          <i class="el-icon-circle-close el-input__icon" slot="suffix" v-if="queryForm.plate_number_head"
            @click="handleIconClick"></i>
          <template slot-scope="{ item }">
            <div class="name">{{ item.name }}</div>
          </template>
        </el-autocomplete>
        <el-input maxlength="6" class="paltetail" v-model="queryForm.plate_number_tail" placeholder="BXXXX" @input="(e) => (queryForm.plate_number_tail = validPlatetoUpperCase(e))
          " @keyup.enter.native="onSubmit()"></el-input>
      </el-form-item>
      <el-form-item label="停车场：">
        <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
          placeholder="请选择停车场" @change="onSubmit()">
          <el-option v-for="item in getAllParkingList(1)" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="抓拍时间：">
        <el-date-picker size="small" v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
          start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="来源：">
        <el-select popper-class="my-select" clearable v-model="queryForm.exitTypeInPark" placeholder="请选择记录来源"
          @clear="queryForm.exitTypeInPark = null" @change="onSubmit()">
          <el-option v-for="item in getOptions('ExitTypeInPark')" :key="item.value" :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="抓拍记录">
      <template slot="heard-right">
        <div>
          <lbButton type="warning" icon="daochu" @click="pullFile" v-if="isShowBtn('6316ace2cc7f0000ae0058c5')">导出
          </lbButton>
        </div>
      </template>
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
          @current-change="currentChange">
          <template slot-scope="{row}" slot="go_image">
            <el-carousel :autoplay="false" v-if="formatImage(row.go_image).length > 1">
              <el-carousel-item v-for="(item, index) in formatImage(row.go_image)" :key="index">
                <el-image class="captureImage" :src="item" :preview-src-list="formatImage(row.go_image)" :z-index="3000">
                  <div slot="error" class="image-slot">
                    <i class="iconfont lebo-picerr"></i>
                    <span>加载失败</span>
                  </div>
                </el-image>
              </el-carousel-item>
            </el-carousel>
            <div v-else-if="formatImage(row.go_image).length === 1">
              <el-image class="captureImage" fit="cover" v-for="(item, index) in formatImage(row.go_image)" :key="index"
                :src="item" :preview-src-list="formatImage(row.go_image)" :z-index="3000">
                <div slot="error" class="image-slot">
                  <i class="iconfont lebo-picerr"></i>
                  <span>加载失败</span>
                </div>
              </el-image>
            </div>
            <div v-else class="image-slot">
              <div class="noPic"></div>
            </div>
          </template>
           <template slot-scope="{row}" slot="plate_number">
            <span>{{ row.plate_number ? row.plate_number : "暂未录入车牌" }}</span>
          </template>
          <template slot-scope="{row}" slot="parking_name">
            {{ row.parking_name ? row.parking_name : "/" }}
          </template>
          <template slot-scope="{row}" slot="go_time">
            {{ $moment(row.go_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="exit_type">
            <span>{{ getWayName("ExitTypeInPark", row.exit_type) }}
            </span>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
        tooltip-effect="dark" border stripe>
        <el-table-column prop="parking_name" label="抓拍图片" width="150">
            <template slot-scope="scope">
              <el-carousel :autoplay="false" v-if="formatImage(scope.row.go_image).length > 1">
                <el-carousel-item v-for="(item, index) in formatImage(scope.row.go_image)" :key="index">
                  <el-image class="captureImage" :src="item" :preview-src-list="formatImage(scope.row.go_image)"
                    :z-index="3000">
                    <div slot="error" class="image-slot">
                      <i class="iconfont lebo-picerr"></i>
                      <span>加载失败</span>
                    </div>
                  </el-image>
                </el-carousel-item>
              </el-carousel>
              <div v-else-if="formatImage(scope.row.go_image).length === 1">
                <el-image class="captureImage" fit="cover" v-for="(item, index) in formatImage(scope.row.go_image)"
                  :key="index" :src="item" :preview-src-list="formatImage(scope.row.go_image)" :z-index="3000">
                  <div slot="error" class="image-slot">
                    <i class="iconfont lebo-picerr"></i>
                    <span>加载失败</span>
                  </div>
                </el-image>
              </div>
              <div v-else class="image-slot">
                <span>暂无图片</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="车牌号" width="120">
          <template slot-scope="scope">
            <span>{{
              scope.row.plate_number ? scope.row.plate_number : "暂未录入车牌"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="parking_name" label="停车场">
          <template slot-scope="scope">
            {{ scope.row.parking_name ? scope.row.parking_name : "/" }}
          </template>
        </el-table-column>
        <el-table-column label="通行时间">
          <template slot-scope="scope">
            {{ $moment(scope.row.go_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="来源">
          <template slot-scope="scope">
            <span>{{ getWayName("ExitTypeInPark", scope.row.exit_type) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="TotalCount" >
      </el-pagination>
      </div>
    </le-card>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import plateNumberHeadList from '@/utils/plate_number_head'
import { getCaptureRecordList } from '@/api/administrative'
export default {
  data () {
    return {
      timeduan: '',
      plateNumberHeadList: plateNumberHeadList,
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        parking_id: '',
        plate_number_head: '',
        plate_number_tail: '',
        go_in_time_start: '',
        go_in_time_end: '',
        exitTypeInPark: null
      },
      // 表格数据
      tableData: [],
      TotalCount: null,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '抓拍图片',
            prop: 'go_image',
            slot: true,
            width: 150
          },
          {
            label: '车牌号',
            prop: 'plate_number',
            slot: true,
            width: 150
          }, {
            label: '停车场',
            prop: 'parking_name',
            slot: true
          }, {
            label: '通行时间',
            prop: 'go_time',
            slot: true
          }, {
            label: '来源',
            prop: 'exit_type',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fnGetCaptureRecordList()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])
  },
  watch: {
    timeduan (newV, oldV) {
      if (newV) {
        this.queryForm.go_in_time_start = newV[0]
        this.queryForm.go_in_time_end = newV[1]
      } else {
        this.queryForm.go_in_time_start = ''
        this.queryForm.go_in_time_end = ''
        this.onSubmit()
      }
    }
  },
  methods: {
    ...mapActions(['requestPorkingList']),
    async fnGetCaptureRecordList () {
      const res = await getCaptureRecordList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        parking_id: this.queryForm.parking_id,
        plate_number_head: this.queryForm.plate_number_head,
        plate_number_tail: this.queryForm.plate_number_tail,
        go_in_time_start: this.queryForm.go_in_time_start,
        go_in_time_end: this.queryForm.go_in_time_end,
        exitTypeInPark: this.queryForm.exitTypeInPark
          ? this.queryForm.exitTypeInPark
          : 0
      })
      console.log(res)
      this.tableData =
        res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.TotalCount =
        res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    querySearch (queryString, cb) {
      var restaurants = this.plateNumberHeadList
      // console.log(restaurants);
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      // console.log('results----', results);
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        // console.log(restaurant);
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) !==
          -1
        )
      }
    },
    handleSelect (item) {
      // console.log('item----', item);
      this.queryForm.plate_number_head = item.value
      this.onSubmit()
    },
    handleIconClick (ev) {
      // console.log(ev);
      this.queryForm.plate_number_head = ''
      this.onSubmit()
    },
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetCaptureRecordList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fnGetCaptureRecordList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetCaptureRecordList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fnGetCaptureRecordList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetCaptureRecordList()
    // },
    // 导出数据
    async pullFile () {
      const res = await getCaptureRecordList({
        PageSize: 0,
        PageIndex: 0,
        parking_id: this.queryForm.parking_id,
        plate_number_head: this.queryForm.plate_number_head,
        plate_number_tail: this.queryForm.plate_number_tail,
        go_in_time_start: this.queryForm.go_in_time_start,
        go_in_time_end: this.queryForm.go_in_time_end,
        exitTypeInPark: this.queryForm.exitTypeInPark
          ? this.queryForm.exitTypeInPark
          : 0
      })
      if (res && res.Code === 200) {
        this.exportFile(res.Data.DataList)
      }
    },
    // 导出
    exportFile (tableData) {
      if (tableData.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = ['车牌号', '停车场', '通行时间', '来源']
          const filterVal = [
            'plate_number',
            'parking_name',
            'go_time',
            'exit_type'
          ]
          const list = tableData
          const data = this.formatJson(filterVal, list)
          // console.log(data);
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '路内停车场抓拍记录' + name, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          var map = {}
          this.$set(map, j, v[j])
          return this.formatter(map, { property: j })
          // 为formatter专门为了码表渲染的组件
        })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // console.log(row);
      // console.log(column);
      switch (column.property) {
        case 'parking_name':
          return row[column.property] ? row[column.property] : '/'
        case 'go_time':
          return this.$moment(row.go_time).format('YYYY-MM-DD HH:mm:ss')
        case 'exit_type':
          return this.getWayName('ExitTypeInPark', row.exit_type)
        default:
          return row[column.property]
      }
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;
}

.query_form {
  /deep/ .el-autocomplete {
    width: 80px;

    .el-input.el-input--small {
      width: inherit !important;
    }
  }
}

.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}

.paltetail {
  width: 200px;
  margin-left: 2px;
}

// 暂无图片
/deep/.image-slot {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .noPic{
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/img/noMessage/noCarsInLight.png');
    background-size: 92% 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .iconfont {
    font-size: 20px;
  }
}

// 单个图片
.captureImage {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  // 加载失败
  /deep/.image-slot {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5f7fa;
    color: #999;

    .iconfont {
      font-size: 20px;
    }
  }

  .el-image__inner {
    max-width: 80px;
    max-height: 80px;
    width: auto;
    height: auto;
  }
}

// 多个图片
/deep/.el-carousel {
  .el-carousel__container {
    position: relative;
    height: 80px;
  }

  .el-carousel__button {
    background-color: #027dfc !important;
    display: none;
  }

  .el-carousel__indicators {
    bottom: 14px;
  }

  .el-carousel__indicator--horizontal {
    padding: 4px 4px;
  }

  .el-carousel__arrow {
    width: 20px;
    height: 20px;
  }

  .el-carousel__arrow--left {
    left: 0;
  }

  .el-carousel__arrow--right {
    right: 50px;
  }
}

.el-dialog__wrapper {
  /deep/ .el-dialog__body {
    padding: 10px 20px 20px;

    .in-out-image {
      width: 100%;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;

      // background-color: #ccc;
      .image-slot {
        width: 100%;
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #f5f7fa;
        color: #999;

        .iconfont {
          font-size: 20px;
        }
      }

      .el-image__inner {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }

    .image-slot {
      width: 100%;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #f5f7fa;
      color: #999;

      .iconfont {
        font-size: 20px;
      }
    }

    .el-carousel {
      width: 100%;
      height: 450px;
      margin-bottom: 10px;

      .el-carousel__container {
        position: relative;
        height: 450px;
      }

      .el-carousel__button {
        background-color: #027dfc !important;
      }

      .el-carousel__indicators {
        bottom: 14px;
      }

      .el-carousel__indicator--horizontal {
        padding: 4px 4px;
      }
    }

    //   .el-image {
    //     width: 100%;
    //     margin-bottom: 10px;
    //     /deep/ .image-slot {
    //       width: 100%;
    //       height: 350px;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       flex-direction: column;
    //       background-color: #f5f7fa;
    //       color: #999;
    //     }
    //     /deep/ .el-image__inner {
    //       max-width: 100%;
    //       max-height: 100%;
    //       width: auto;
    //       height: auto;
    //     }
    //   }
  }
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 350px!important;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small{
  width: 180px;
}
</style>
